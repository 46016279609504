<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <div class="row">
            <div class="col-md-10 col-12">
              <b>Daftar Surat Masuk Retur</b>
            </div>
            <div class="col-md-2 col-12" v-if="canSeeAll">
              <select
                class="pull-right form-control"
                v-model="from"
                @change="getFrom"
              >
                <option value="me">Diri Sendiri</option>
                <option value="all">Satu Unit</option>
              </select>
            </div>
          </div>
        </h3>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :header="headers"
              :onAction="get"
              :onDetail="doDetail"
              :forbidDetail="forbidRead"
              :forbidDelete="true"
            />
          </div>
        </div>
      </div>

      <hr />
      <div class="card card-body printableArea">
        <h4>Petunjuk</h4>
        <table>
          <tr>
            <td>
              <span class="btn-sm btn-success mr-1">
                <i class="mdi mdi-magnify"></i>
              </span>
            </td>
            <td style="font-size: 13px">
              Untuk melihat detail surat yang diretur
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";

export default {
  components: {
    RotateSquare5,
    Table,
  },
  data() {
    return {
      from: "all",
      headers: {
        description: {
          label: "Isi Ringkas",
          sortable: true,
        },
        no_opd: {
          label: "Nomor Kendali",
          sortable: true,
        },
        nomor_surat: {
          label: "Nomor Surat",
          sortable: true,
        },
        asal_surat: {
          label: "Asal Surat",
          sortable: true,
        },
        tanggal_diterima: {
          label: "Tanggal Diteruskan",
          sortable: true,
          type: "date",
        },
        statustindaklanjut: {
          label: "Status",
        },
        penerima: {
          label: "Tujuan Disposisi",
        },
        instruksi: {
          label: "Instruksi",
        },
      },
    };
  },
  computed: {
    items() {
      let data = [];
      if (this.$store.state.documentInRejected.items !== undefined) {
        if (this.$store.state.documentInRejected.items.items !== undefined) {
          if (
            !Array.isArray(this.$store.state.documentInRejected.items.items) &&
            this.$store.state.documentInRejected.items.items !== undefined
          ) {
            data = Object.keys(
              this.$store.state.documentInRejected.items.items
            ).map((i) => this.$store.state.documentInRejected.items.items[i]);
          } else {
            data = this.$store.state.documentInRejected.items.items;
          }
        }
      }
      return data;
    },
    filters() {
      let filt = {};
      if (this.$store.state.documentInRejected.items !== undefined) {
        if (this.$store.state.documentInRejected.items.filters !== undefined) {
          filt = this.$store.state.documentInRejected.items.filters;
        }
      }
      return filt;
    },
    state() {
      return this.$store.state.documentInRejected;
    },
    forbidUpdate() {
      if (this.$store.state.profile.permissions.document_in) {
        return !this.$store.state.profile.permissions.document_in.update;
      }
      return false;
    },
    forbidRead() {
      if (this.$store.state.profile.permissions.document_in) {
        return !this.$store.state.profile.permissions.document_in.read;
      }
      return false;
    },
    forbidDel() {
      if (this.$store.state.profile.permissions.document_in) {
        return !this.$store.state.profile.permissions.document_in.delete;
      }
      return false;
    },
    canSeeAll() {
      if (this.$store.state.profile.permissions.document_in_all) {
        return this.$store.state.profile.permissions.document_in_all.read;
      }
      return false;
    },
  },
  mounted() {
    const state = {
      loaded: false,
      items: [],
      from: this.from,
    };
    this.$store.commit("documentInRejected/STATE", state);
    this.get(state);
  },
  methods: {
    get(val) {
      this.$store.dispatch("documentInRejected/getDocumentInRejected", val);
    },
    doDetail(val) {
      this.$store.dispatch("documentInRejected/onDetail", val);
    },
    getFrom() {
      const state = {
        from: this.from,
      };
      this.$store.commit("documentInRejected/STATE", state);
      this.get(state);
    },
  },
};
</script>
